/** @jsx jsx */
import { jsx } from 'theme-ui';
import { theme } from 'gatsby-plugin-theme-ui/index';

import ReactMarkdown from 'react-markdown';
import MyImage from 'components/Common/MyImage';
import ImageRow from 'components/case-studies/ImageRow';

interface CaseStudyBlockMapperProps {
  block: any;
}
const CaseStudyBlockMapper = ({ block }: CaseStudyBlockMapperProps) => {
  switch (block.strapi_component) {
    case 'case-studies.rich-text':
      return (
        <ReactMarkdown
          sx={{
            ...theme?.styles?.markdown,
            mt: 5,
            whiteSpace: 'pre-wrap',
          }}
        >
          {block.content}
        </ReactMarkdown>
      );

    case 'case-studies.full-width-image':
      return (
        <MyImage
          src={`${process.env.GATSBY_API_URL}${block.image.url}`}
          alt={block.image.alternativeText}
          sx={{
            mt: 5,
            width: '100%',
            height: '433px',
            borderRadius: '25px',
          }}
        />
      );

    case 'case-studies.image-row':
      // console.log(block)
      return (
        <ImageRow
          images={block}
          sx={{
            mt: 5,
          }}
        />
      );

    default:
      return null;
  }
};

export default CaseStudyBlockMapper;
