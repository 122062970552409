/** @jsx jsx */
import { jsx, Flex } from "theme-ui"

import MyImage from "components/Common/MyImage"

interface ImageRowProps {
  images: any
  className?: string
}
const ImageRow = ({
  images: { image_1, image_2, image_3 },
  className
}: ImageRowProps) => {
  const image_1Url = process.env.GATSBY_API_URL + image_1.url
  const image_2Url = process.env.GATSBY_API_URL + image_2.url
  const image_3Url = process.env.GATSBY_API_URL + image_3.url

  return (
    <Flex
      className={className}
      sx={{
        justifyContent: ["space-between", "space-between"],
        alignItems: "center",
        flexWrap: "wrap",
        ".childImage": {
          width: ["170px", "285px"],
          height: ["184px", "309px"],
          img: {
            borderRadius: "25px"
          }
        }
      }}
    >
      <MyImage src={image_1Url} alt={image_1.alt} className="childImage" />
      <MyImage src={image_2Url} alt={image_2.alt} className="childImage" />
      <MyImage src={image_3Url} alt={image_3.alt} className="childImage" />
    </Flex>
  )
}

export default ImageRow
