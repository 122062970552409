/** @jsx jsx */
import { jsx, Box } from "theme-ui"

interface MyImageProps {
  src: string
  alt: string
  objectFit?: "cover" | "contain"
  className?: string
}
const MyImage = ({ src, alt, objectFit, className }: MyImageProps) => {
  return (
    <Box variant="styles.myImage" className={className}>
      <img
        src={src}
        alt={alt}
        sx={{
          objectFit: objectFit ? objectFit : "cover"
        }}
      />
    </Box>
  )
}

export default MyImage
