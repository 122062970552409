/** @jsx jsx */
import { jsx, Container } from 'theme-ui';
import { graphql } from 'gatsby';
import { IGatsbyImageData, GatsbyImage, getImage } from 'gatsby-plugin-image';

import Layout from 'components/Common/Layout';
import Header from 'components/Header/Header';
import CaseStudyBlockMapper from 'components/case-studies/CaseStudyBlockMapper';
import MoveYourBrandCard from 'components/Footer/MoveYourBrandCard';

const CaseStudyDetailsPage = ({
  data: {
    strapiCaseStudy: { hero, title, preview, blocks, SEO },
  },
}) => {
  const heroImageSrc = process.env.GATSBY_API_URL + hero.localFile.publicURL;
  // console.log(blocks)

  return (
    <Layout
      seo={{
        title: SEO.meta_title,
        description: SEO.meta_description,
        shareImage: SEO.share_image,
      }}
    >
      <Header theme="BLUE" />

      <main
        sx={{
          mt: [3, 0],
        }}
      >
        {/* Hero image */}
        {hero && (
          <Container variant="container1200">
            <GatsbyImage
              image={
                getImage(
                  hero.localFile.childImageSharp.gatsbyImageData
                ) as IGatsbyImageData
              }
              alt={hero.alternativeText}
              sx={{
                width: '100%',
                height: ['205px', '480px'],
                borderRadius: '40px',
              }}
            />
          </Container>
        )}

        {/* Content */}
        {blocks && (
          <Container
            variant="container1000"
            sx={{
              color: 'themeBlack',
            }}
          >
            {blocks.map((block, index) => (
              <CaseStudyBlockMapper
                key={`CaseStudyBlock:${index}`}
                block={block}
              />
            ))}
          </Container>
        )}
      </main>

      <MoveYourBrandCard
        sx={{
          m: '0 auto',
          position: 'relative',
          bottom: -5,
          zIndex: 2,
        }}
      />
    </Layout>
  );
};

export default CaseStudyDetailsPage;

export const caseStudyDetailsPageQuery = graphql`
  query CaseStudyDetailsPage($slug: String!) {
    strapiCaseStudy(slug: { eq: $slug }) {
      hero {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        alternativeText
      }
      title
      preview
      blocks
      SEO {
        meta_title
        meta_description
        share_image {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;
